<template>
    <!--contents-->
    <div id="contents" ref="top">
            <!--콘텐츠-->
            <div class="conBox" id="scrollArea">
                <!--이관신청 정보 확인-->
                <div class="patientEnroll">
                    <div class="box">
                        <v-form ref="registForm" lazy-validation>
                            <ul class="cont">
                                <li>
                                    <p class="styleTit">이관 신청 정보</p>
                                    <table class="tbl tblInp" v-if="requestData">
                                        <caption>이관 신청 정보</caption>
                                        <colgroup>
                                            <col style="width:20%"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>환자명</th>
                                                <td>{{ requestData.patientNm }}</td>
                                            </tr>
                                            <tr>
                                                <th>환자번호</th>
                                                <td>{{ requestData.patientSn }}</td>
                                            </tr>
                                            <tr>
                                                <th>신청자</th>
                                                <td>{{ requestData.requestNm }} ({{ requestData.requestId }})</td>
                                            </tr>
                                            <tr>
                                                <th>인수자</th>
                                                <td>{{ requestData.transfereeNm }} ({{ requestData.transfereeId }})</td>
                                            </tr>
                                            <tr>
                                                <th>진행상태</th>
                                                <td><v-chip :class="requestData.state&&getStateChipClass(requestData.state)">{{ requestData.stateStr }}</v-chip></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <p class="styleTit">이관 진행 이력</p>
                                    <table class="tbl tblInp" v-if="requestLog">
                                        <caption>이관 진행 이력</caption>
                                        <colgroup>
                                            <col style="width:8%"/>
                                            <col style="width:25%" />
                                            <col style="width:18%"/>
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <th>No.</th>
                                            <th>변경일시</th>
                                            <th>진행상태</th>
                                            <th>사유</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in requestLog" :key="index">
                                                <td class="text-center">{{ requestLogCnt-index }}</td>
                                                <td class="text-center">{{ item.crtDtStr }}</td>
                                                <td class="text-center">{{ item.stateStr }}</td>
                                                <td>{{ item.comment }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li></li>
                            </ul>
                        </v-form>
                    </div>
                    <div class="btnW popBtn mt40">
                        <button class="btn default" @click="$router.push({name:'transferManage'})" v-ripple>{{CONST_LIST}}</button>
                        <div v-if="requestData.state === 'REQST'">
                            <button class="btn solid" @click="changeState('CONFM')" v-ripple>{{ CONST_CONFM }}</button>
                            <button class="btn danger ml5" @click="returnRequest()" v-ripple>{{ CONST_RETURN }}</button>
                        </div>
                    </div>
                </div>
                <!--//이관신청 정보 확인-->
            </div>
            <!--//콘텐츠-->
            <v-dialog v-model="openPopup">
                <CommentPopup :state="'RETURN'" :requestNo="requestNo" @closeCmtPopup="closeCmtPopup" />
            </v-dialog>
        </div>
</template>

<script>
import API_REQUEST_INFO from '../../../API/transferRequest/detail'
import API_REQUEST_LOG_LIST from '../../../API/transferRequest/logList'
import API_CHANGE_STATE from '../../../API/transferRequest/changeState'
import { rules } from '../../../mixin/rules'

export default {
    mixins: [rules],
    components: {
        CommentPopup: () => import('../../../popup/commentPopup.vue'),
    },
    inject: ["setRightSideHidden"],
    props: {},
    data() {
        return {
            requestNo: this.$route.params.no,
            isGettingItems: false,
            requestData: {},
            requestLog: [],
            requestLogCnt: null,
            openPopup: false,
        };
    },
    watch: {
        isDetail: function (value) {
            if (value === false) {
                document.getElementById('scrollArea').scrollTop = 0; 
            }
        }
    },
    created() {
    },
    mounted() {
        this.setRightSideHidden(false);
        this.getData(this.requestNo)
    },
    destroyed() {
    },
    methods: {
        async getData(no) {
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let resInfo = await API_REQUEST_INFO.request(no);
            let resList = await API_REQUEST_LOG_LIST.request(no);
            if (resInfo.isSuccess) {
                this.requestData = resInfo.data;
            } else {
                this.showPopup(resInfo.errorMsg, resInfo.status);
            }

            if (resList.isSuccess) {
                this.requestLog = resList.list;
                this.requestLogCnt = this.requestLog.length;
            } else {
                this.showPopup(resList.errorMsg, resList.status);
            }
            this.isGettingItems = false;
        },
        async changeState(state){
        	if(!this.requestNo) {
                this.showPopup('유효하지 않은 신청입니다<br>다시 확인해주세요.')
                return;
            }
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let res = await API_CHANGE_STATE.request(this.requestNo, state, '');
            if (res.isSuccess) {
                this.showPopup("승인이 완료되었습니다.")
                this.getData(this.requestNo)
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGettingItems = false;
        },
        returnRequest(){
            this.openPopup = true;
        },
        closeCmtPopup(){
            this.openPopup = false;
            this.getData(this.requestNo)
        },
        getStateChipClass(state) {
            return state.toLowerCase(); 
        },
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tbl{overflow: hidden;}
//.patientEnroll .cont > li:last-child{width: calc(50% - 20px)}

.styleTit{margin-bottom: 16px;}
.v-input--selection-controls{margin-top:0; padding-top:0;}
.spaceBetween{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    .input-wrapper{
        margin-bottom: 0;
        ::v-deep .v-text-field__slot {
            input {
                text-align: right;
            }
        }
        
    }
}
::v-deep .v-input__slot{
    padding: 0 !important;
    .v-text-field__slot {
        margin-right: 0 !important;
        textarea{
            margin-top: 0 !important;
            padding: 5px !important;
        }
    }
}

.v-application {
    #contents {
        ::v-deep .primary--text {
            color: var(--color-point) !important;
        }
    }
}
.v-input__slot{padding:0;}
.tblScroll{height: auto !important; max-height: 600px;}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{padding:0 !important;}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

.patientEnroll .cont .tblInp{height: auto;}


// 상태값 css
.v-chip.reqst{background-color:#FFF9F2; color: #FFA22E;}
.v-chip.confm{background-color:#EBFAFC; color: #00BAD3;}
.v-chip.cancl{background-color:#F5F5F5; color: #666;}
.v-chip.return{background-color:#FFF1F3; color: #FF2D52;}

.btn.solid.red{background-color: #FF2D52 !important; color: #FFF1F3 !important; border: 1px solid rgba(255,255,255,0.5) !important;}

</style>