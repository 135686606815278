export const rules = {
    methods: {
        passwordRules(str) { // 비밀번호 체크
            const text = str !== undefined ? str.trim() : undefined
            if (text === undefined) {
                return [];
            }
            const errors = [];

            if (text.length < 8) {
                errors.push('비밀번호는 8자리 이상이어야 합니다.');
            }

            const hasNumber = /\d/.test(text);
            const hasLetter = /[a-zA-Z]/.test(text);
            const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(text);

            if (!(hasNumber && hasLetter && hasSpecialChar)) {
                errors.push('비밀번호는 숫자, 영문, 특수문자를 모두 포함해야 합니다.');
            }

            return errors;
        },
        confirmPasswordRules(str1, str2) {
            const text1 = str1 !== undefined ? str1.trim() : undefined
            const text2 = str2 !== undefined ? str2.trim() : undefined
            if (text1 === undefined) {
                return [];
            }
            return [
                value => !!value || `새 비밀번호를 입력하세요`,
                value => !(value && text1 !== text2) || `동일한 비밀번호를 입력해주세요`,
            ]
        },
        notNullRules(str) { // 필수입력
            const text = str.trim()
            return [
                value => !!value || `${text}은(는) 필수입력입니다`
            ]
        },
        userIdRules(str) { // 한글 입력 제한
            const text = str
            return [
                value => !!value || `${text}의 값을 입력해주세요`,
                value => !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || `${text}은(는) 한글을 입력할 수 없습니다`
            ]
        },
        onlyDecimalRules(str) {
            const text = str
            return [
                value => !/^[-+]?[0-9]*\.?[0-9]*$/.test(value) || `${text}은(는) 숫자와 소수점(.)만 입력 가능합니다`
            ]
        },
        numericRule(str) {
            const text = str.trim()
            return [
                value => !!value || `${text}은(는) 필수입력입니다`,
                value => /^\d+$/.test(value) || `${text}은(는) 숫자만 입력 가능합니다`,
            ]
        },
        none() {
            return []
        }
    }
}

export default { rules };